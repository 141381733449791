<template>
  <div ref="conditions" class="report_items report_seeds" :class="{'active': showMore}">
    <div v-if="showButton" class="btn_less" @click="toggle">
      <i class="icon-caret-down down"/>
      <i class="icon-caret-up up"/>
    </div>


    <template v-for="item in Equips" :key="item.ref_id">
      <DiaryViewConditionsItem
        :logo="item.logo"
        :name="item.name"
        :name_link="item.name_link"
        :hint="item.hint"
        :hint_link="item.hint_link"
        :category="item.category"
        :count="item.count"
        :data-props="item.dataProps"
        :faza="item.faza"
      /> 
    </template>


    <!-- <template v-if="itemsEquip">
      <template v-for="item in itemsEquip" :key="item.ref_id">

        <template v-if="item.props?.faza !== undefined">
          <DiaryViewConditionsItem
            v-if="item.props?.type"
            :logo="item.item_brand.logo_s || mapperSetupDiaryIcon[item.props?.type]"
            :name="item.name + LampPropsName(item)"
            :hint="item.item_brand.name ? item.item_brand.name : t('category_' + item.category)"
            :hint_link="item.item_brand.link"
            :category="item.category"
            :count="item.cnt"
            :data-props="item.props"
            :faza="item.props?.faza"
          />
          <DiaryViewConditionsItem
            v-else
            :logo="item.item_brand.logo_s"
            :logo_webp="item.item_brand.logo_s"
            :logo_link="item.item_brand.link"
            :count="item.cnt"
            :name="item.name + LampPropsName(item)"
            :name_link="item.link"
            :hint="item.item_brand.name ? item.item_brand.name : t('category_' + item.category)"
            :hint_link="item.item_brand.link"
            :category="item.category"
            :data-props="item.props"
            :faza="item.props?.faza"
          />
        </template>

        <DiaryViewConditionsItem
          v-else
          :logo="item.item_brand.logo_s"
          :logo_webp="item.item_brand.logo_s"
          :logo_link="item.link"
          :name="item.name ? item.name : item.item_brand.name"
          :name_link="item.link"
          :category="item.category"
          :hint="item.item_brand.name ? item.item_brand.name : t('category_' + item.category)"
          :hint_link="item.item_brand.link"
        />
      </template>
    </template> -->
    

    <!-- TODO | remove after debug, cause this already in items_equip  -->
    <!-- <template v-if="data.items_nutrient">
      <template v-for="item in data.items_nutrient" :key="item.name">
        <DiaryViewConditionsItem
          :logo="item.logo_s"
          :logo_webp="item.logo_s"
          :logo_link="item.link"
          :name="item.name"
          :name_link="item.link"
          :category="'nutrient'"
          :hint="$t('diary_info_nutrients')"
          :hint_link="item.link"
        />
      </template>
    </template> -->


    <template v-for="award in data.items_award" :key="award.name">
      <DiaryViewAwardItem
        :icon="'/images/trophy/' + award.icon + '.svg'"
        :name="award.name"
        :link="award.link"
        :place="award.place"
      />
    </template>

    <DiaryViewConditionsItem
      v-if="data.type_room == 1"
      :icon="'ic ic-room-1'"
      :name="$t('universal_type_room_indoor')"
      :hint="$t('universal_room_type_title')"
      @click="openWiki('room', 'indoor')"
    />

    <DiaryViewConditionsItem
      v-if="data.type_room == 2"
      :icon="'ic ic-room-2'"
      :name="$t('universal_type_room_outdoor')"
      :hint="$t('universal_room_type_title')"
      @click="openWiki('room', 'outdoor')"
    />

    <DiaryViewConditionsItem
      v-if="data.type_room == 3"
      :icon="'ic ic-room-3'"
      :name="$t('universal_type_room_greenhouse')"
      :hint="$t('universal_room_type_title')"
      @click="openWiki('room', 'greenhouse')"
    />

    <template v-for="method in props.data.items_method" :key="method.id">
      <DiaryViewConditionsItem
        v-if="method.id >= 0"
        :icon="'ic ic-method-' + method.id"
        :name="$constants.typeMethodShort[method.id] ? $t($constants.typeMethodShort[method.id]) : ''"
        :hint="$t('create_week_view_number_plural') + ' ' + weeksCroped(method?.weeks) "
        @click="openWiki('method', $constants.typeMethodCode[method.id])"
      />
    </template>

    <template v-for="medium in mediums" :key="medium.id">
      <DiaryViewConditionsItem
        v-if="medium.id >= 0"
        :icon="'ic ic-medium-' + medium.id"
        :name="medium.id == 7 && medium.name ? medium.name : $t($constants.medium[medium.id])"
        :hint="$t('diary_info_grow_medium')"
        :percent="medium.value"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import {ECategorySort} from "~/types/Enums.ts"

const { t } = useI18n();
const { $constants } = useNuxtApp();
import debounce from 'lodash/debounce';

const mapperSetupDiaryIcon = {
  1: "/images/setup_diary/setup_diary_icons-07.svg",
  2: "/images/setup_diary/setup_diary_icons-08.svg",
  3: "/images/setup_diary/setup_diary_icons-06.svg",
}

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});
const showMore = ref(false);
const conditions = ref(null);
const showButton = ref(false);




const sortEquips = (list) => {
  return list.sort((a, b) => {
    return ECategorySort.indexOf(a.category) - ECategorySort.indexOf(b.category);
  });
}

const groupEquips = (list) => {
  let grouped = list.reduce((acc, item) => {
    if (!item.id || !item.item_brand.id) {
      if (acc[item.ref_id]) {
        acc[item.ref_id].cnt += 1;
      } else {
        item.cnt = 1;
        acc[item.ref_id] = item;
      }
    } else {
      const key = `${item.id}_${item.item_brand.id}`;
      if (acc[key]) {
        acc[key].cnt += 1;
      } else {
        item.cnt = 1;
        acc[key] = item;
      }
    }
    return acc;
  }, {});

  return Object.values(grouped);  
}



const Equips = computed(() => {
  let list = [];

  // common euips
  if (props.data.items_equip.length) {
    list = props.data.items_equip.map((item) => {

      let logo = item.item_brand.logo_s 
      let name = (item.name ?? '') + LampPropsName(item)
      let name_link = item.link
      let hint = item.item_brand.name
      let hint_link = item.item_brand.link
      
      
      if(!item.id && !name){
        logo = item.item_brand.logo_s
        name = item.item_brand.name
        name_link = item.item_brand.link
        hint = t('category_' + item.category)
        hint_link = null
      } 

      let category = item.category
      let count = item.cnt
      let dataProps = item.props
      let faza = item.props?.faza 
      let ref_id = item.ref_id

      return {
        ref_id: ref_id,
        logo: logo,
        name: name,
        name_link: name_link,
        hint: hint,
        hint_link: hint_link,
        category: category,
        count: count,
        dataProps: dataProps,
        faza: faza
      }
    });
  }

  // nutrients
  if (props.data.items_nutrient.length) {
    list = list.concat(props.data.items_nutrient.map((item) => {
      return {
        logo: item.logo_s,
        name: item.name,
        name_link: item.link,
        hint: t('diary_info_nutrients'),
        hint_link: item.link,
        category: 'nutrient'
      }
    }));
  }

  return sortEquips(groupEquips(list));
})


 

const LampPropsName = (item) => {
  if(item.category != 'lamp'){
    return '';
  }
  // $constants.lampTypes[item.props.type] + '/' + item.props?.power + 'W'
  let power = item.props?.power ? '/' + item.props.power + 'W' : '';
  let type = item.props?.type ? ($constants.typeLightShort[item.props.type] ? t($constants.typeLightShort[item.props.type]) : '') : '';
  if(!type && !power){
    return '';
  }
  return ' ' + type + power;
}


// const itemsEquip = computed(() => {
//   if (!props.data.items_equip.length) {
//     return []
//   }

//   let items = JSON.parse(JSON.stringify(props.data.items_equip))

//   const groupedByIdAndBrand = items.reduce((acc, item) => {
//     const key = (!item.id || !item.item_brand.id) ? item.ref_id : `${item.id}_${item.item_brand.id}`
//     if (acc[key]) {
//       acc[key].cnt += 1;
//     } else {
//       item.cnt = 1;
//       acc[key] = item;
//     }
//     return acc;
//   }, {});

//   let result = Object.values(groupedByIdAndBrand);

//   result.sort((a, b) => {
//     return ECategorySort.indexOf(a.category) - ECategorySort.indexOf(b.category);
//   })

//   return result;

// })

const mediums = computed(() => {
  return props.data.items_medium
})

const weeksCroped = (arr) => {
  // method.weeks?.join(', ')

  if(!arr.length){
    return t('diary_info_technique');
  }

  if(!arr[0]){
    return t('diary_info_technique');
  }

  arr = arr.filter((item) => {
    return item
  });

  arr = arr.map((item) => {
    return parseInt(item);
  });

  arr = arr.sort((a, b) => a - b);

  let result = [];
  let start = arr[0];

  for (let i = 1; i <= arr.length; i++) {
    // Check if elements not goes one by one, or end of array
    if (arr[i] !== arr[i - 1] + 1 || i === arr.length) {
      // If current element - is not the beginning of the subsequence, add range
      if (start !== arr[i - 1]) {
        result.push(`${start}-${arr[i - 1]}`);
      } else {
        result.push(`${start}`);
      }

      // Start new subsequence
      start = arr[i];
    }
  }

  return result.join(', ');
};

const checkOverflow = debounce((event) => {

  if (conditions.value) {
    let totalWidth = 0;
    const children = conditions.value.querySelectorAll('.item');

    children.forEach((child) => {
      totalWidth += child.offsetWidth + 10;
    });

    showButton.value = totalWidth > conditions.value.offsetWidth*2 - 30;
  }

}, 300);

const toggle = function() {
  showMore.value = !showMore.value;
}

const openWiki = (type, name) => {
  useWikiModal().open(type, name);
}

onMounted(() => {
  checkOverflow();
  window.addEventListener('resize', checkOverflow);
});

onUpdated(checkOverflow);

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkOverflow);
})
</script>

<style scoped>



.report_items{
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 100px;
  align-items: flex-start;
  position: relative;
  padding-right: 45px;
  margin-top: 10px;
  margin-bottom: 15px;
  gap: 10px;
}
.report_items.active{
  max-height: none;
}
.report_items .item.condition_item{
  cursor: default;
}
.report_items .item{
  display: flex;
  position: relative;
  align-items: center;
  height: 45px;
}
.report_items .item .logo{
  max-height: 40px;
  max-width: 40px;
  margin-right: 10px;
  width: 100%;
}
.report_items .item .logo_link{
  min-width: 40px;
}
.report_items .item .faza{
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 0px 3px;
  border-radius: 3px;
  border: 1px gray solid;
  line-height: 0.76rem;
  left: 7px;
}
.report_items .item .faza.faza_0{

  border: 1px #a2da7a solid;
  color: #a2da7a;
}
.report_items .item .faza.faza_gray{

  border: 1px #000000 solid;
  color: #000000;
}
.report_items .item .faza.faza_1{

  border: 1px #ff8853 solid;
  color: #ff8853;
}
.report_items .item .info{
  display: flex;
  flex-direction: column;
  /*width: calc(100% - 46px);*/
}
.report_items .item .info .name{
  color: var(--un-text-color);
  /*max-width: 120px;*/
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
.report_items .item .info .ttl{
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: gray;
  white-space: nowrap;
}
.report_items .item .info .ttl .lnk{
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: gray;
  white-space: nowrap;
}
.report_items .item .info .ttl .bn{
  background-color: orange;
  align-items: center;
  justify-content: center;
  padding: 3px;
  display: inline-block;
  border-radius: 3px;
  color: black;
  font-size: 0.9rem;
  margin-left: 4px;
  cursor: pointer;
  margin-top: -2px;
}
.report_items .item .info .ttl .bn{
  background-color: #ffaf1d;
  color: black;
}
.report_items .item .info .ttl .cnt{
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 2px;
  padding: 0px 3px;
}
.report_items .item.partner{
  background-color: #fff8d5;
  padding: 5px;
  border-radius: 3px;
}
.report_items .item .action{
  margin-left: 10px;
}
.report_items .item .action a{
  color: var(--un-text-color);
}
.report_items .ic{
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.report_items.report_seeds .ic{
  width: 40px;
  height: 40px;
}


.report_items .btn_less{
  position: absolute;
  right: 10px;
  font-size: 1.5rem;
  color: gray;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color:  var(--un-background-color-gray-dark);
  text-align: center;
  bottom: 5px;
}
.report_items.less .btn_less{
  display: inline-block;
}
.report_items.active .btn_less{

}
.report_items .btn_less .down{
  display: inline-block;
  margin: 0px;
}
.report_items.active .btn_less .down{
  display: none;
}
.report_items .btn_less .up{
  display: none;
  margin: 0px;
}
.report_items.active .btn_less .up{
  display: inline-block;
}
</style>